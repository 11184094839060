const breakpoints = {
    currentBreakpointName: null,

    breakpoints: [
        { name: 'xs', breakpoint: null, query: '(max-width: 500px)' },
        { name: 'sm', breakpoint: '500px', query: '(min-width: 500px) and (max-width: 650px)' },
        { name: 'md', breakpoint: '650px', query: '(min-width: 650px) and (max-width: 768px)' },
        { name: 'lg', breakpoint: '768px', query: '(min-width: 768px) and (max-width: 816px)' },
        { name: 'xl', breakpoint: '816px', query: '(min-width: 816px) and (max-width: 972px)' },
        { name: '2xl', breakpoint: '972px', query: '(min-width: 972px) and (max-width: 1144px)' },
        { name: '3xl', breakpoint: '1144px', query: '(min-width: 1144px) and (max-width: 1440px)' },
        { name: '4xl', breakpoint: '1440px', query: '(min-width: 1440px)' },
    ],

    getCurrentBreakpointName() {
        const currentBreakpoint = this.breakpoints.find((bp) => {
            return window.matchMedia(bp.query).matches;
        });

        return currentBreakpoint.name;
    },

    getBreakpoint(name) {
        return this.breakpoints.find((bp) => { return bp.name === name; });
    },

    getBreakpointIndex(name) {
        return this.breakpoints.indexOf(this.getBreakpoint(name));
    },

    atBreakpointOrLower(desiredBreakpointName) {
        const desiredBreakpointIndex = this.getBreakpointIndex(desiredBreakpointName);
        const currentBreakpointIndex = this.getBreakpointIndex(this.currentBreakpointName);

        return currentBreakpointIndex <= desiredBreakpointIndex;
    },

    atBreakpointOrHigher(desiredBreakpointName) {
        const desiredBreakpointIndex = this.getBreakpointIndex(desiredBreakpointName);
        const currentBreakpointIndex = this.getBreakpointIndex(this.currentBreakpointName);

        return currentBreakpointIndex >= desiredBreakpointIndex;
    },

    registerBreakpointListeners() {
        this.breakpoints.forEach((breakpoint) => {
            const mediaQuery = breakpoint.query;

            const mediaQueryList = window.matchMedia(mediaQuery);

            mediaQueryList.addEventListener('change', (e) => {
                if (e.matches) {
                    this.currentBreakpointName = breakpoint.name;

                    window.dispatchEvent(new CustomEvent('breakpoint-changed', {
                        detail: {
                            name: breakpoint.name,
                        },
                    }));
                }
            });
        });
    },

    boot() {
        this.currentBreakpointName = this.getCurrentBreakpointName();

        this.registerBreakpointListeners();
    },
};

export default breakpoints;
